import { useEffectOnce } from 'react-use';
import { reaction } from 'mobx';

import useStore from './useStore';

export default function(cb: Function) {
  const accommodationStore = useStore('accommodationStore');

  useEffectOnce(() => reaction(
    () => accommodationStore.hasFilters,
    () => {
      if (!accommodationStore.hasFilters) {
        cb();
      }
    }
  ));
}
