import { useState, Dispatch, SetStateAction } from 'react';

const useForm = (callback?: Function) => {
  const [inputs, setInputs]: [any, Dispatch<SetStateAction<{}>>] = useState({});

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (callback) {
      callback(inputs);
    }
  };

  const handleDateChange = (date: Date, name: string) => {
    setInputs({ ...inputs, [name]: date });
  };

  const handleSelectChange = (name: string, value: string | number) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleRecaptcha = (token: string | null) => {
    if (token) {
      setInputs({ ...inputs, recaptcha: token });
    }
  };

  const handleSliderChange = (values: number[]) => {
    setInputs({ ...inputs, minPrice: values[0], maxPrice: values[1] });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.persist();
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)) {
      setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
      event.target.setCustomValidity('');
    } else {
      setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
      event.target.setCustomValidity(`Password must contain at least eight characters, including at least one number and includes both lower and uppercase letters.`);
    }
  };

  const handlePasswordConfirm = (event: React.ChangeEvent<HTMLInputElement>, password: string) => {
    event.persist();
    if(password !== event.target.value) {
      setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
      event.target.setCustomValidity(`The supplied passwords do not match`);
    } else {
      event.target.setCustomValidity('');
      setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
    }
  };

  return {
    handleDateChange,
    handleInputChange,
    handlePasswordChange,
    handlePasswordConfirm,
    handleRecaptcha,
    handleSelectChange,
    handleSliderChange,
    handleSubmit,
    inputs
  };
};

export default useForm;
