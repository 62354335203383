import React from 'react';
import './styles.scss';

import BannerCurve from 'images/layout/banner-curve.svg';
import BannerBottom from 'images/layout/banner-bottom-curve.svg';

interface Props {
  image?: string;
  tint?: boolean;
  tintColour: { from: string; to: string };
  curveColour?: string;
  height: number;
}

const Banner = ({ image, tint, tintColour, curveColour = "#f1fafb", height }: Props) => (
  <div styleName="banner" style={{ height: `${height}px`, marginBottom: `-${height}px` }}>
    {image && (<img src={image} styleName="background" />)}
    {tint && (
      <div styleName="tint" style={{ background: `linear-gradient(-10deg, ${tintColour.from}, ${tintColour.to})` }} />
    )}
    <div styleName="bottomCurve" style={!image ? { display: 'none'} : {}}>
      <BannerBottom fill={curveColour} />
    </div>
    <div styleName="curve" style={!image ? { bottom: 0} : {}}>
      <BannerCurve fill={curveColour} />
    </div>
  </div>
);

export default Banner;
